const awsconfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id:
    'us-east-1:e30844e8-0d26-4dfb-a767-9ea6f42cdc47',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_77aITdsig',
  aws_user_pools_web_client_id: '46oddo11un61dao93eig95g9gd',
  oauth: {
    domain: 'exeltis-prod.auth.us-east-1.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'https://www.cursodegestanteregenesis.com.br/',
    redirectSignOut: 'https://www.cursodegestanteregenesis.com.br/',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
};

export default awsconfig;